module.exports = [
  {
    lang: 'fi',
    path: '/',
    logo: { href: '/', title: 'Pielahirsi Oy' },
    nav: [
      { href: '/about', title: 'Tietoja'},
      { href: '/blog', title: 'Uutiset'},
      { href: '/products', title: 'Tuotteemme'},
      { href: '/contact', title: 'Yhteystiedot'},
    ],
  },
  {
    lang: 'en',
    path: '/en/',
    logo: { href: '/en/', title: 'Pielahirsi Oy' },
    nav: [
        { href: '/en/products', title: 'Pricing'},
        { href: '/en/about', title: 'Services'},
        { href: '/blog', title: 'Blog'},
        { href: '/en/contact', title: 'Contact'},
    ],
  },
]