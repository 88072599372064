import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import nav from "../data/navbar";
import company from '../data/company'
import seo from '../data/seo'


const Footer = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeLanguage: this.props.lang,
    }
  }

  render() {
    const filteredData = nav.filter(data => data.lang === this.state.activeLanguage)[0] || [];
    
    const socialFacebook = seo[0].social.filter(data => data.service === "facebook")[0].href || '';

    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Pielahirsi Oy"
            style={{ width: '14em', height: '10em' }}
          />
          <h4 className="has-text-white-ter">
            { this.props.lang === 'fi' ? 'Y-tunnus: ' : 'Business ID: '} { company[0].govid }
          </h4>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">

              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    { filteredData.nav.map(item => (
                        <li key={ item.href }>
                          <Link className="navbar-item" to={ item.href } key={item.href}>
                            { item.title }
                          </Link>
                        </li>
                    )) }
                  </ul>
                </section>
              </div>

              {/*<div className="column is-4 social">*/}
              {/*  <a title="facebook" href={socialFacebook} target="_blank" rel="noreferrer">*/}
              {/*    <img*/}
              {/*      src={facebook}*/}
              {/*      alt="Facebook"*/}
              {/*      style={{ width: '1em', height: '1em' }}*/}
              {/*    />*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
